import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useAppDispatch } from "../store/hooks";
import { matchActions } from "../store/matchSlice";

const Toolbar = () => {
  const dispatch = useAppDispatch();

  const buttonConfigs = [
    { label: "Undo", onClick: () => {} },
    { label: "Refresh", onClick: () => {} },
    {
      label: "Change Court",
      onClick: () => dispatch(matchActions.changeCourt()),
    },
    {
      label: "Change Serve",
      onClick: () => dispatch(matchActions.changeServe()),
    },
    { label: "Player Statistics", onClick: () => {} },
    { label: "Play History", onClick: () => {} },
    { label: "Info", onClick: () => {} },
    { label: "Exit", onClick: () => {} },
  ];

  return (
    <Paper elevation={3} sx={{ py: 1 }}>
      <Stack direction="row">
        {buttonConfigs.map((button, index) => (
          <Button
            key={index}
            variant="outlined"
            sx={{
              height: "40px",
              flex: 1,
              lineHeight: 1.2,
              textTransform: "none",
            }}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        ))}
      </Stack>
    </Paper>
  );
};

export default Toolbar;
