import React from "react";
import PageContainer from "components/PageContainer";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import SelectPlayer from "./SelectPlayer";

const InputLabel: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Grid
    size={4}
    sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
  >
    <Typography>{children}</Typography>
  </Grid>
);

const CreateScoreboard: React.FC = () => {
  const [alignment, setAlignment] = React.useState("singles");

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };
  return (
    <PageContainer title="Create Scoreboard">
      <Grid container rowSpacing={2} columnSpacing={4}>
        <InputLabel>Match Type</InputLabel>
        <Grid size={8}>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Match Type"
          >
            <ToggleButton value="singles">Singles</ToggleButton>
            <ToggleButton value="doubles">Doubles</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <InputLabel>Play 1</InputLabel>
        <Grid size={8}>
          <SelectPlayer/>
        </Grid>
        <InputLabel>Play 2</InputLabel>
        <Grid size={8}>
          <TextField id="outlined-basic" variant="outlined" />
        </Grid>
        <InputLabel>Game Rule</InputLabel>
        <Grid size={8}>
          <TextField id="outlined-basic" variant="outlined" />
        </Grid>
        <InputLabel>Where</InputLabel>
        <Grid size={8}>
          <TextField id="outlined-basic" variant="outlined" />
        </Grid>
        <InputLabel>Court</InputLabel>
        <Grid size={8}>
          <TextField id="outlined-basic" variant="outlined" />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default CreateScoreboard;
