import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddPointState,
  MatchState,
  PlayerSide,
  PlayerState,
} from "./MatchState";
import { RootState } from "./tennisStore";

const initialPlayerState: PlayerState = {
  id: "",
  name: "Player Name",
  gameScore: 0,
  setScore: [0, 0, 0],
  isServer: false,
  isFirstServer: true,
};

const createPlayer = (
  id: string,
  name: string,
  isServer: boolean
): PlayerState => ({
  ...initialPlayerState,
  id,
  name,
  isServer,
});

const player1 = createPlayer("1", "Player1", true);
const player2 = createPlayer("2", "Player2", false);

const initialState: MatchState = {
  matchType: "Singles",
  players: [player1, player2],
  leftPlayer: player1,
  rightPlayer: player2,
  gameRule: "",
  location: "",
  court: "",
  beginTime: new Date().toISOString(),
  matchTime: "",
  endTime: "",
  gameStatus: "",
  currentSet: 1,
  isTieBreak: false,
};

export const matchSlice = createSlice({
  name: "match",
  initialState,
  reducers: {
    fault: (state) => {
      const server = getCurrentServer(state);
      if (server.isFirstServer) {
        server.isFirstServer = false;
      } else {
        server.isFirstServer = true;
        addPoint(state, { side: getServerSide(state), reason: "DoubleFault" });
      }
    },
    point: (state, action: PayloadAction<AddPointState>) => {
      addPoint(state, action.payload);
    },
    updateGameStatus: (state, action: PayloadAction<string>) => {
      state.gameStatus = action.payload;
    },
    changeServe: (state) => {
      state.leftPlayer.isServer = !state.leftPlayer.isServer;
      state.rightPlayer.isServer = !state.rightPlayer.isServer;
      updatePlayers(state);
    },
    changeCourt: (state) => {
      [state.leftPlayer, state.rightPlayer] = [
        state.rightPlayer,
        state.leftPlayer,
      ];
      updatePlayers(state);
    },
    unforceError: (state, action: PayloadAction<string>) => {
      const pointState: AddPointState = {
        side: action.payload === "Left" ? "Right" : "Left",
        reason: "UnforceError",
      };
      addPoint(state, pointState);
    },
  },
});

function addPoint(state: MatchState, pointState: AddPointState) {
  const scoringPlayer =
    pointState.side === "Left" ? state.leftPlayer : state.rightPlayer;
  scoringPlayer.gameScore++;
  calculateGamePoint(state);
  updatePlayers(state);
}

const calculateGamePoint = (state: MatchState) => {
  const leftPoint = state.leftPlayer.gameScore;
  const rightPoint = state.rightPlayer.gameScore;
  const gamePoint = state.isTieBreak ? 7 : 3;
  let status = "";

  if (leftPoint === rightPoint && leftPoint >= gamePoint) {
    status = "DEUCE";
  } else if (isGameWon(leftPoint, rightPoint, gamePoint)) {
    if (leftPoint > rightPoint) {
      state.leftPlayer.setScore[state.currentSet - 1]++;
    } else {
      state.rightPlayer.setScore[state.currentSet - 1]++;
    }
    addSetScore(state);
    resetGameScores(state);
  } else if (leftPoint >= gamePoint || rightPoint >= gamePoint) {
    status = getSpecialGameStatus(state, leftPoint, rightPoint);
  }

  state.gameStatus = status;
  state.isTieBreak = isTieBreak(state);
};

const isGameWon = (
  leftPoint: number,
  rightPoint: number,
  gamePoint: number
) => {
  return (
    (leftPoint >= gamePoint + 1 || rightPoint >= gamePoint + 1) &&
    Math.abs(leftPoint - rightPoint) >= 2
  );
};

const getSpecialGameStatus = (
  state: MatchState,
  leftPoint: number,
  rightPoint: number
) => {
  if (isMatchPoint(state)) {
    return "MATCH POINT";
  } else if (isSetPoint(state)) {
    return "SET POINT";
  } else if (state.isTieBreak) {
    return "TIE BREAK";
  } else if (
    (leftPoint >= 3 && state.rightPlayer.isServer) ||
    (rightPoint >= 3 && state.leftPlayer.isServer)
  ) {
    return "BREAK POINT";
  } else {
    return "GAME POINT";
  }
};

const isMatchPoint = (state: MatchState) => {
  return (
    state.currentSet === state.leftPlayer.setScore.length && isSetPoint(state)
  );
};

const isTieBreak = (state: MatchState) => {
  const [leftScore, rightScore] = getSetScore(state);
  return leftScore === 6 && rightScore === 6;
};

const isSetPoint = (state: MatchState) => {
  const [leftScore, rightScore] = getSetScore(state);
  return (
    (leftScore >= 5 || rightScore >= 5) && Math.abs(leftScore - rightScore) >= 1
  );
};

function addSetScore(state: MatchState) {
  const [leftScore, rightScore] = getSetScore(state);
  if (isSetWon(leftScore, rightScore)) {
    state.currentSet++;
    state.isTieBreak = false;
  }
  updatePlayers(state);
}

const isSetWon = (leftScore: number, rightScore: number) => {
  return (
    ((leftScore > 5 || rightScore > 5) &&
      Math.abs(leftScore - rightScore) >= 2) ||
    leftScore === 7 ||
    rightScore === 7
  );
};

function resetGameScores(state: MatchState) {
  state.leftPlayer.gameScore = 0;
  state.rightPlayer.gameScore = 0;
}

function updatePlayers(state: MatchState) {
  state.players = [state.leftPlayer, state.rightPlayer];
}

function getCurrentServer(state: MatchState) {
  return state.leftPlayer.isServer ? state.leftPlayer : state.rightPlayer;
}

function getServerSide(state: MatchState): PlayerSide {
  return state.leftPlayer.isServer ? "Left" : "Right";
}

function getSetScore(state: MatchState) {
  const leftScore = state.leftPlayer.setScore[state.currentSet - 1];
  const rightScore = state.rightPlayer.setScore[state.currentSet - 1];
  return [leftScore, rightScore];
}

export const getMatchState = (state: RootState) => state.match;

export const matchActions = matchSlice.actions;

export default matchSlice.reducer;
