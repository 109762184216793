import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function getTabProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface TabPageItem {
  item?: React.ReactNode;
  title: string;
}
interface TabPageProps {
  items: TabPageItem[];
}
const TabPage = ({ items }: TabPageProps) => {
  const [value, setValue] = useState(0);
  const handleTagChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        paddingTop: 8,
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: 800,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleTagChange}
        sx={{
          borderRight: 1,
          borderColor: "divider",
          marginRight: 4,
        }}
      >
        {items.map((item, index) => (
          <Tab key={index} label={item.title} {...getTabProps(index)} />
        ))}
      </Tabs>
      {items.map((item, index) => (
        <TabPanel key={index} value={value} index={index}>
          {item.item}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabPage;
