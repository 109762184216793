import apiBase from "api/ApiBase";
import { CreateUpdatePlayer } from "../types/createUpdatePlayer";
import { Player } from "../types/player";

class PlayerService {
  async create(data: CreateUpdatePlayer): Promise<Player> {
    try {
      const res = await apiBase.post<Player>("/app/player", data);
      return res.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }

  async getList(): Promise<Player[]> {
    try {
      const res = await apiBase.get<Player[]>("/app/player");
      return res.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
}

export const playerService = new PlayerService();
