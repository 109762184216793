import MUIAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { alpha, Button, Divider, Drawer, MenuItem } from "@mui/material";
import NavLink from "./NavLink";
import Sitemark from "../Sitemark";
import MenuIcon from "@mui/icons-material/Menu";
import React, { JSX } from "react";
import { useAuth } from "../../services/auths/useAuth";
import UserMenu from "./UserMenu";
import { Link } from "react-router";

interface AppBarProps {
  children: JSX.Element;
}
const AppBar = ({ children }: AppBarProps) => {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const auth = useAuth();
  return (
    <MUIAppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: "transparent",
        backgroundImage: "none",
      }}
    >
      <Toolbar
        variant="regular"
        disableGutters
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          flexShrink: 0,
          backdropFilter: "blur(24px)",
          border: "1px solid",
          borderColor: (theme.vars || theme).palette.divider,
          backgroundColor: theme.vars
            ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
            : alpha(theme.palette.background.default, 0.4),
          boxShadow: (theme.vars || theme).shadows[1],
        })}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexShrink: 0,
          }}
        >
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}
          >
            <Sitemark />
            <Box sx={{ display: { xs: "none", md: "flex" }, mx: 4 }}>
              {children}
              <NavLink title="En" menuItems={["中文", "EN", "FR"]} />
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 1,
              alignItems: "center",
            }}
          >
            {auth.user ? (
              <UserMenu />
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => auth.signinRedirect()}
              >
                Sign in
              </Button>
            )}
          </Box>

          <Box sx={{ display: { sm: "", md: "none" } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: "30px", p: "4px" }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: "60dvw",
                  p: 2,
                  backgroundColor: "background.paper",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexGrow: 1,
                    gap: 2,
                  }}
                >
                  {auth.user && <UserMenu />}
                </Box>
                <MenuItem component={Link} to="/tennis">
                  Tennis
                </MenuItem>
                <Divider />
                <MenuItem>
                  {auth.user ? (
                    <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      sx={{ width: "100%" }}
                      onClick={() => auth.logout()}
                    >
                      Sign Out
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      sx={{ width: "100%" }}
                      onClick={() => auth.signinRedirect()}
                    >
                      Sign in
                    </Button>
                  )}
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </Container>
      </Toolbar>
    </MUIAppBar>
  );
};
export default AppBar;
