import React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { playerService } from "./servcies/playerService";

interface AddPlayerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (player: { id: string; name: string }) => void;
  dialogValue: { id: string; name: string };
  setDialogValue: React.Dispatch<React.SetStateAction<{ id: string; name: string }>>;
}

const AddPlayer: React.FC<AddPlayerProps> = ({ open, onClose, onSubmit, dialogValue, setDialogValue }) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await playerService.create({ name: dialogValue.name, avatarUrl: "" });
    onSubmit(dialogValue);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add a new player</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="name"
            value={dialogValue.name}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                name: event.target.value,
              })
            }
            label="Player Name"
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPlayer;
