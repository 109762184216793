import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "./AppBar";
import { Outlet } from "react-router";
import AppTheme from "./theme/AppTheme";
import NavLink from "./NavLink";

const MainLayout = () => {
  return (
    <AppTheme disableCustomTheme>
      <CssBaseline enableColorScheme />
      <AppBar>
        <NavLink title="Tennis" to="/tennis" />
      </AppBar>
      <Outlet />
    </AppTheme>
  );
};

export default MainLayout;
