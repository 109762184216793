import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { PlayerSide } from "features/Tennis/store/MatchState";
import { useAppDispatch } from "../store/hooks";
import { matchActions } from "../store/matchSlice";

interface GamePointProps {
  side: PlayerSide;
  score: number;
  isTieBreak: boolean;
}

const GamePoint: React.FC<GamePointProps> = ({ side, score, isTieBreak }) => {
  const dispatch = useAppDispatch();
  const getPointDisplay = (point: number): string => {
    if (isTieBreak) return point.toString();
    switch (point) {
      case 0:
        return "0";
      case 1:
        return "15";
      case 2:
        return "30";
      case 3:
        return "40";
      case 4:
        return "AD";
      default:
        return "Invalid point";
    }
  };

  // Event handler to add a point
  const handleAddPoint = () => {
    dispatch(
      matchActions.point({
        side,
        reason: "Point",
      })
    );
  };

  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        height: 120,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: 4,
          ...(side === "Left" ? { left: 4 } : { right: 4 }),
        }}
      >
        POINT
      </Typography>
      <Stack
        className="score-number"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          fontSize: "48px",
          height: "100%",
          cursor: "pointer",
          userSelect: "none",
        }}
        onClick={handleAddPoint}
        aria-label={`Score for ${side} player is ${getPointDisplay(score)}`}
      >
        {getPointDisplay(score)}
      </Stack>
    </Paper>
  );
};

export default GamePoint;
