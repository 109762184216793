import React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

interface SetScorePros {
  name: string;
  scores: number[];
  isServer: boolean;
  currentSet: number;
}

const SetScore: React.FC<SetScorePros> = ({
  name,
  scores,
  isServer,
  currentSet,
}) => {
  return (
    <Stack direction="row">
      <Stack direction="row" sx={{ flex: 1, justifyContent: "center" }}>
        <Typography variant="h6" sx={{ color: isServer ? "green" : "inherit" }}>
          {name}
        </Typography>
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <Stack direction="row" spacing={8}>
          {scores.map((score, index) => (
            <Typography
              variant="h5"
              className="score-number "
              key={index}
              sx={{ color: index + 1 === currentSet ? "green" : "inherit" }}
            >
              {score}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SetScore;
