import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LogoImage from "../assets/Logo.svg";

const logoStyle = {
  width: "auto",
  height: "35px",
  cursor: "pointer",
};
const Sitemark = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        px: 0,
      }}
    >
      <img src={LogoImage} style={logoStyle} alt="WhatsNow" />
      <Typography
        variant="h5"
        color="#3275af"
        component="a"
        href="/"
        noWrap
        sx={{
          textDecoration: "none",
          mx: 2,
          display: { xs: "none", sm: "inline" },
        }}
      >
        What's Now
      </Typography>
    </Box>
  );
};

export default Sitemark;
