import { Provider } from "react-redux";
import { Outlet } from "react-router";
import { store } from "features/Tennis/store/tennisStore";
import AppTheme from "layouts/MainLayout/theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "layouts/MainLayout/AppBar";
import NavLink from "layouts/MainLayout/NavLink";

const TennisLayout = () => {
  return (
    <AppTheme disableCustomTheme>
      <CssBaseline enableColorScheme />
      <AppBar>
        <>
          <NavLink title="Tennis Scoreboard" to="/tennis-scoreboard" />
          <NavLink title="Tennis" to="/tennis" />
        </>
      </AppBar>
      <Provider store={store}>
        <Outlet />
      </Provider>
    </AppTheme>
  );
};
export default TennisLayout;
