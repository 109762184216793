import React from "react";
import Container from "@mui/material/Container";
import MatchScore from "./MatchScore";
import MatchTime from "./MatchTime";
import GameScore from "./GameScore";
import Toolbar from "./Toolbar";
import { useAppSelector } from "../store/hooks";
import { getMatchState } from "../store/matchSlice";

interface ScoreboardProps {}

const Scoreboard: React.FC<ScoreboardProps> = () => {
  const matchState = useAppSelector(getMatchState);

  return (
    <Container>
      <MatchScore
        player1={matchState.players[0]}
        player2={matchState.players[1]}
        currentSet={matchState.currentSet}
      />
      <MatchTime
        leftPlayer={matchState.leftPlayer}
        rightPlayer={matchState.rightPlayer}
        startTime={new Date(matchState.beginTime)}
      />
      <GameScore
        leftPlayer={matchState.leftPlayer}
        rightPlayer={matchState.rightPlayer}
        gameStatus={matchState.gameStatus}
        isTieBreak={matchState.isTieBreak}
      />
      <Toolbar />
    </Container>
  );
};

export default Scoreboard;
