import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { User } from "oidc-client-ts";
import { AuthSetting } from "../services/auths/AuthSetting";

export class ApiBase {
  private axiosInstance: AxiosInstance;

  constructor() {
    const baseURL = process.env.REACT_APP_API_BASE_URL ?? "";

    this.axiosInstance = axios.create({
      baseURL: `${baseURL}/api`,
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        const user: User | null = JSON.parse(
          sessionStorage.getItem(
            `oidc.user:${AuthSetting.authority}:${AuthSetting.client_id}`
          ) || "null"
        );
        if (user && user.access_token) {
          config.headers["Authorization"] = `Bearer ${user.access_token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get<T>(url, config);
  }

  public post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post<T>(url, data, config);
  }

  public put<T>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put<T>(url, data, config);
  }

  public delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete<T>(url, config);
  }
}

const apiBase = new ApiBase();

export default apiBase;
