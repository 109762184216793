import { StyledEngineProvider } from "@mui/material/styles";
import Scoreboard from "./components/Scoreboard";

const Tennis = () => {
  return (
    <StyledEngineProvider injectFirst>
      <Scoreboard />
    </StyledEngineProvider>
  );
};

export default Tennis;
