import { UserManagerSettings } from "oidc-client-ts";

export const AuthSetting: UserManagerSettings = {
  authority: process.env.REACT_APP_AUTHORITY ?? "",
  client_id: "WhatsNow_App",
  redirect_uri: `${window.location.origin}`,
  post_logout_redirect_uri: `${window.location.origin}`,
  response_type: "code",
  scope: "openid profile email offline_access WhatsNow",
  automaticSilentRenew: true,
  loadUserInfo: true,
};
