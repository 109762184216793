import { Outlet } from "react-router";
import { withAuthenticationRequired } from "../services/auths/withAuthenticationRequired";

interface Props {
  isPublic?: boolean;
  isAuthorized?: boolean;
}

const ProtectedRoute = ({ isPublic = false, isAuthorized = false }: Props) => {
  const canAccess = isPublic || isAuthorized;
  if (!canAccess) {
    // Before redirecting, store the page in sessionStorage
    sessionStorage.setItem("returnUrl", window.location.href);
  }
  const ProtectedOutlet = withAuthenticationRequired(Outlet, {
    OnRedirecting: () => <div>Redirecting to the login page...</div>,
  });

  return canAccess ? <Outlet /> : <ProtectedOutlet />;
};

export default ProtectedRoute;
