import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useAppDispatch } from "../store/hooks";
import { matchActions } from "../store/matchSlice";
import { PlayerSide, PlayerState } from "../store/MatchState";
interface PointReasonProps {
  side: PlayerSide;
  player: PlayerState;
}
const PointReason: React.FC<PointReasonProps> = ({ side, player }) => {
  const dispatch = useAppDispatch();
  return (
    <ButtonGroup orientation="vertical">
      <Button
        sx={{ height: "40px" }}
        disabled={!player.isServer}
        onClick={() => {
          dispatch(matchActions.point({ side: side, reason: "Ace" }));
        }}
      >
        ACE
      </Button>
      <Button
        sx={{ height: "40px" }}
        onClick={() => {
          dispatch(matchActions.point({ side: side, reason: "Winner" }));
        }}
      >
        WINNER
      </Button>
      <Button
        sx={{ height: "40px", lineHeight: 1.2 }}
        onClick={() => {
          dispatch(matchActions.fault());
        }}
        disabled={!player.isServer}
      >
        {player.isServer && player.isFirstServer ? "FAULT" : "DOUBLE FAULT"}
      </Button>
      <Button
        sx={{ height: "40px", lineHeight: 1 }}
        onClick={() => {
          dispatch(matchActions.unforceError(side));
        }}
      >
        UNFORCE ERROR
      </Button>
    </ButtonGroup>
  );
};

export default PointReason;
