import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router";
import { StyledEngineProvider } from "@mui/material/styles";
import { AuthProvider } from "./services/auths/AuthProvider";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";
import "./i18n";

import App from "./App";
import { User } from "oidc-client-ts";
const onSigninCallback = (_user: User | void): void => {
  console.log("onSigninCallback");
  window.history.replaceState({}, document.title, window.location.pathname);
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider onSigninCallback={onSigninCallback}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
