import React from "react";
import { UserManager, type UserManagerSettings, User } from "oidc-client-ts";
import type {
  ProcessResourceOwnerPasswordCredentialsArgs,
  SignoutResponse,
} from "oidc-client-ts";

import { AuthContext } from "./AuthContext";
import { initialAuthState } from "./AuthState";
import { reducer } from "./reducer";
import { hasAuthParams, signinError, signoutError } from "./utils";
import { AuthSetting } from "./AuthSetting";

export interface AuthProviderBaseProps {
  children?: React.ReactNode;
  onSigninCallback?: (user: User | undefined) => Promise<void> | void;
  skipSigninCallback?: boolean;
  matchSignoutCallback?: (args: UserManagerSettings) => boolean;
  onSignoutCallback?: (
    resp: SignoutResponse | undefined
  ) => Promise<void> | void;
  onRemoveUser?: () => Promise<void> | void;
}

export interface AuthProviderNoUserManagerProps
  extends AuthProviderBaseProps,
    UserManagerSettings {
  userManager?: never;
}

export interface AuthProviderUserManagerProps extends AuthProviderBaseProps {
  userManager?: UserManager;
}

export type AuthProviderProps =
  | AuthProviderNoUserManagerProps
  | AuthProviderUserManagerProps;

const userManagerContextKeys = [
  "clearStaleState",
  "querySessionStatus",
  "revokeTokens",
  "startSilentRenew",
  "stopSilentRenew",
] as const;
const navigatorKeys = [
  "signinPopup",
  "signinSilent",
  "signinRedirect",
  "signinResourceOwnerCredentials",
  "signoutPopup",
  "signoutRedirect",
  "signoutSilent",
] as const;
const unsupportedEnvironment = (fnName: string) => () => {
  throw new Error(
    `UserManager#${fnName} was called from an unsupported context. If this is a server-rendered page, defer this call with useEffect() or pass a custom UserManager implementation.`
  );
};
const UserManagerImpl = typeof window === "undefined" ? null : UserManager;

export const AuthProvider = (props: AuthProviderProps): React.JSX.Element => {
  const {
    children,
    onSigninCallback,
    skipSigninCallback,
    matchSignoutCallback,
    onSignoutCallback,
    onRemoveUser,
    userManager: userManagerProp = null
  } = props;
  const [userManager] = React.useState(() => {
    return (
      userManagerProp ??
      (UserManagerImpl
        ? new UserManagerImpl(AuthSetting as UserManagerSettings)
        : ({ settings: AuthSetting } as UserManager))
    );
  });

  const [state, dispatch] = React.useReducer(reducer, initialAuthState);
  const userManagerContext = React.useMemo(
    () =>
      Object.assign(
        {
          settings: userManager.settings,
          events: userManager.events,
        },
        Object.fromEntries(
          userManagerContextKeys.map((key) => [
            key,
            userManager[key]?.bind(userManager) ?? unsupportedEnvironment(key),
          ])
        ) as Pick<UserManager, (typeof userManagerContextKeys)[number]>,
        Object.fromEntries(
          navigatorKeys.map((key) => [
            key,
            userManager[key]
              ? async (
                  args: ProcessResourceOwnerPasswordCredentialsArgs & never[]
                ) => {
                  dispatch({
                    type: "NAVIGATOR_INIT",
                    method: key,
                  });
                  try {
                    return await userManager[key](args);
                  } catch (error) {
                    dispatch({ type: "ERROR", error: error as Error });
                    return null;
                  } finally {
                    dispatch({ type: "NAVIGATOR_CLOSE" });
                  }
                }
              : unsupportedEnvironment(key),
          ])
        ) as Pick<UserManager, (typeof navigatorKeys)[number]>
      ),
    [userManager]
  );
  const didInitialize = React.useRef(false);

  React.useEffect(() => {
    if (!userManager || didInitialize.current) {
      return;
    }
    didInitialize.current = true;

    void (async (): Promise<void> => {
      // sign-in
      try {
        let user: User | undefined | null = null;

        // check if returning back from authority server
        if (hasAuthParams() && !skipSigninCallback) {
          user = await userManager.signinCallback();
          if (onSigninCallback) await onSigninCallback(user);
          else {
            const returnUrl = sessionStorage.getItem("returnUrl");
            if (returnUrl) {
              window.location.replace(returnUrl);
              sessionStorage.removeItem("returnUrl");
            } else {
              window.location.replace("/");
            }
          }
        }
        user = !user ? await userManager.getUser() : user;
        dispatch({ type: "INITIALISED", user });
      } catch (error) {
        dispatch({ type: "ERROR", error: signinError(error) });
      }

      // sign-out
      try {
        if (
          matchSignoutCallback &&
          matchSignoutCallback(userManager.settings)
        ) {
          const resp = await userManager.signoutCallback();
          onSignoutCallback && (await onSignoutCallback(resp));
        }
      } catch (error) {
        dispatch({ type: "ERROR", error: signoutError(error) });
      }
    })();
  }, [
    userManager,
    skipSigninCallback,
    onSigninCallback,
    onSignoutCallback,
    matchSignoutCallback,
  ]);

  React.useEffect(() => {
    if (!userManager) return undefined;
    const handleUserLoaded = (user: User) => {
      dispatch({ type: "USER_LOADED", user });
    };
    userManager.events.addUserLoaded(handleUserLoaded);

    const handleUserUnloaded = () => {
      dispatch({ type: "USER_UNLOADED" });
    };
    userManager.events.addUserUnloaded(handleUserUnloaded);

    const handleUserSignedOut = () => {
      dispatch({ type: "USER_SIGNED_OUT" });
    };
    userManager.events.addUserSignedOut(handleUserSignedOut);

    const handleSilentRenewError = (error: Error) => {
      dispatch({ type: "ERROR", error });
    };
    userManager.events.addSilentRenewError(handleSilentRenewError);

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
      userManager.events.removeUserUnloaded(handleUserUnloaded);
      userManager.events.removeUserSignedOut(handleUserSignedOut);
      userManager.events.removeSilentRenewError(handleSilentRenewError);
    };
  }, [userManager]);

  const removeUser = React.useCallback(async () => {
    if (!userManager) unsupportedEnvironment("removeUser");
    await userManager.removeUser();
    onRemoveUser && (await onRemoveUser());
  }, [userManager, onRemoveUser]);

  const logout = React.useCallback(async () => {
    await userManager.signoutRedirect();
  }, [userManager]);

  const contextValue = React.useMemo(() => {
    return {
      ...state,
      ...userManagerContext,
      removeUser,
      logout,
    };
  }, [state, userManagerContext, removeUser, logout]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
