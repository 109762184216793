import { useState, useEffect } from "react";
import apiBase from "api/ApiBase";
import { UserProfileState } from "./UserProfileState";
import Container from "@mui/material/Container";
import TabPage from "components/TabPage";
import GeneralSetting from "./components/GeneralSetting";
import ChangePassword from "./components/ChangePassword";

const UserProfile = () => {
  const [userProfile, setUserProfile] = useState<UserProfileState | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = (await apiBase.get<UserProfileState>("account/my-profile"))
          .data;
        setUserProfile(data);
      } catch (error) {
        console.error("Failed to fetch profile", error);
      }
    };

    fetchProfile();
  }, []);
  const handleSave = async (user: UserProfileState) => {
    await apiBase.put<UserProfileState>("account/my-profile", user);

    const data = (await apiBase.get<UserProfileState>("account/my-profile"))
      .data;
    setUserProfile(data);
  };
  if (!userProfile) {
    return <div>Loading...</div>;
  } else {
    return (
      <Container maxWidth="lg">
        <TabPage
          items={[
            {
              title: "General",
              item: (
                <GeneralSetting
                  userProfile={userProfile!}
                  onSave={handleSave}
                />
              ),
            },
            { title: "Security", item: <ChangePassword /> },
            { title: "Tennis Player", item: <>Tennis Player</> },
          ]}
        />
      </Container>
    );
  }
};

export default UserProfile;
