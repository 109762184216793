import Home from "../pages/Home";
import MainLayout from "../layouts/MainLayout";
import { renderRoutes } from "./GenerateRoute";
import TennisLayout from "features/Tennis/layouts/TennisLayout";
import Tennis from "../features/Tennis/Tennis";
import UserProfile from "../features/Accounts/UserProfile";
import CreateScoreboard from "features/Tennis/CreateMatch";

export interface AppRoute {
  name: string;
  title: string;
  component?: React.ComponentType<any>;
  path?: string;
  routes?: AppRoute[];
}

export interface AppLayout {
  layout: React.ComponentType<any>;
  isPublic?: boolean;
  routes: AppRoute[];
}

export const routes: AppLayout[] = [
  {
    layout: MainLayout,
    isPublic: true,
    routes: [
      {
        name: "index",
        title: "Home Page",
        component: Home,
        path: "/",
      },
      {
        name: "home",
        title: "Home Page",
        component: Home,
        path: "/home",
      },
      {
        name: "profile",
        title: "User Profile",
        component: UserProfile,
        path: "/user/profile",
      },
    ],
  },
  {
    layout: TennisLayout,
    isPublic: true,
    routes: [
      {
        name: "Tennis",
        title: "Tennis",
        component: Tennis,
        path: "/tennis",
      },
      {
        name: "Tennis Scoreboard",
        title: "Tennis Scoreboard",
        component: CreateScoreboard,
        path: "/tennis-scoreboard",
      },
    ],
  },
];

export const Routes = renderRoutes(routes);
