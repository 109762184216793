import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { UserProfileState } from "../UserProfileState";
import Button from "@mui/material/Button";

interface GeneralSettingProps {
  userProfile: UserProfileState;
  onSave: (userProfile: UserProfileState) => void;
}
const GeneralSetting = ({ userProfile, onSave }: GeneralSettingProps) => {
  const [state, setState] = useState(userProfile);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => {
      return { ...prev, [event.target.id]: event.target.value };
    });
  };
  return (
    <Grid container spacing={4}>
      <Grid size={6}>
        <TextField
          required
          id="surname"
          label="First Name"
          value={state.surname}
          onChange={handleChange}
        />
      </Grid>
      <Grid size={6}>
        <TextField
          required
          id="lastname"
          label="Last Name"
          value={state.lastname}
          onChange={handleChange}
        />
      </Grid>
      <Grid size={12}>
        <TextField
          required
          id="email"
          type="email"
          label="Email"
          value={state.email}
          onChange={handleChange}
        />
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSave(state)}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default GeneralSetting;
