import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Player } from "./types/player";
import AddPlayer from "./AddPlayer";
import { playerService } from "./servcies/playerService";

const filter = createFilterOptions<Player>();

export default function SelectPlayer() {
  const [value, setValue] = React.useState<Player | null>(null);
  const [open, toggleOpen] = React.useState(false);
  const [players, setPlayers] = React.useState<Player[]>([]);
  useEffect(() => {
    const fetchPlayers = async () => {
      const list = await playerService.getList();
      setPlayers(list);
    };

    fetchPlayers();
  }, []);

  const handleClose = () => {
    setDialogValue({
      id: "",
      name: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    id: "",
    name: "",
  });

  const handleSubmit = (newPlayer: { id: string; name: string }) => {
    setValue(newPlayer);
  };

  return (
    <>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
                id: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
              id: "",
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              id: "1",
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={players}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.name}
            </li>
          );
        }}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => <TextField {...params} />}
      />
      <AddPlayer
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        dialogValue={dialogValue}
        setDialogValue={setDialogValue}
      />
    </>
  );
}
