import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

interface PageContainerProps {
  title: string;
  children: React.ReactNode;
  onSave?: () => void;
  onCancel?: () => void;
}
const PageContainer = ({
  title,
  children,
  onSave,
  onCancel,
}: PageContainerProps) => {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 80px)",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: 65,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">{title}</Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            padding: 2,
          }}
        >
          {children}
        </Box>

        <Box
          sx={{
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          gap={4}
        >
          <Button
            variant="contained"
            size="medium"
            sx={{ width: 100 }}
            onClick={() => onSave?.()}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="medium"
            sx={{ width: 100 }}
            color="error"
            onClick={() => onCancel?.()}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PageContainer;
