import React from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router";

interface NavLinkProps {
  title: string;
  to?: string;
  menuItems?: string[];
}

const NavLink = ({ title, to, menuItems }: NavLinkProps) => {
  const nav = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (to) nav(to);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button sx={{ py: "6px", px: "12px" }} onClick={handleClick}>
        <Typography variant="subtitle2" color="text.primary">
          {title}
        </Typography>
        {menuItems && <KeyboardArrowDownIcon />}
      </Button>

      {menuItems && (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={handleClose}>
              {item}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default NavLink;
