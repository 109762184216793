import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import PointReason from "./PointReason";
import GamePoint from "./GamePoint";
import { PlayerState } from "../store/MatchState";

interface GameScoreProps {
  leftPlayer: PlayerState;
  rightPlayer: PlayerState;
  gameStatus: string;
  isTieBreak: boolean;
}

const GameScore: React.FC<GameScoreProps> = ({
  leftPlayer,
  rightPlayer,
  gameStatus,
  isTieBreak,
}) => {
  return (
    <Paper elevation={2}>
      <Stack direction="row" spacing={1}>
        <Stack sx={{ flex: 2 }}>
          <PointReason side="Left" player={leftPlayer} />
        </Stack>
        <Stack sx={{ flex: 8 }}>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 3,
            }}
          >
            {gameStatus ? gameStatus : isTieBreak ? "TIE BREAK" : ""}
          </Stack>
          <Stack direction="row" spacing={1} sx={{ flex: 9 }}>
            <Stack sx={{ flex: 1 }}>
              <GamePoint
                side="Left"
                score={leftPlayer.gameScore}
                isTieBreak={isTieBreak}
              />
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <GamePoint
                side="Right"
                score={rightPlayer.gameScore}
                isTieBreak={isTieBreak}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ flex: 2 }}>
          <PointReason side="Right" player={rightPlayer} />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default GameScore;
