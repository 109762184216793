export const hasAuthParams = (location = window.location): boolean => {
  let searchParams = new URLSearchParams(location.search);
  if (
    (searchParams.get("code") || searchParams.get("error")) &&
    searchParams.get("state")
  ) {
    return true;
  }

  searchParams = new URLSearchParams(location.hash.replace("#", "?"));
  if (
    (searchParams.get("code") || searchParams.get("error")) &&
    searchParams.get("state")
  ) {
    return true;
  }

  return false;
};

const normalizeErrorFn =
  (fallbackMessage: string) =>
  (error: unknown): Error => {
    if (error instanceof Error) {
      return error;
    }
    return new Error(fallbackMessage);
  };

export const signinError = normalizeErrorFn("Sign-in failed");
export const signoutError = normalizeErrorFn("Sign-out failed");
