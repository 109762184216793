import React from "react";
import Stack from "@mui/material/Stack";
import moment from "moment";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { PlayerState } from "../store/MatchState";

interface MatchTimeProps {
  leftPlayer: PlayerState;
  rightPlayer: PlayerState;
  startTime: Date;
}
const MatchTime: React.FC<MatchTimeProps> = ({
  leftPlayer,
  rightPlayer,
  startTime,
}) => {
  const [timeOfDay, setTimeOfDay] = React.useState(moment());

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setTimeOfDay(moment());
    }, 60000);

    return () => {
      clearInterval(timerId);
    };
  }, []);
  const matchDuration = moment.duration(timeOfDay.diff(startTime));
  return (
    <Paper elevation={2}>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", gap: 2, p: 1 }}
      >
        <Stack
          direction="row"
          sx={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: leftPlayer.isServer ? "green" : "inherit" }}>
            {leftPlayer.name}
          </Typography>
          <Stack>
            <Typography>Time of Day</Typography>
            <Typography variant="h6">{timeOfDay.format("HH:mm")}</Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          sx={{
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack>
            <Typography>Match Time</Typography>
            <Typography variant="h6">
              {moment.utc(matchDuration.asMilliseconds()).format("HH:mm")}
            </Typography>
          </Stack>
          <Typography
            sx={{
              alignContent: "flex-end",
              color: rightPlayer.isServer ? "green" : "inherit",
            }}
          >
            {rightPlayer.name}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default MatchTime;
